<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4998 1.5V2.33812C16.8982 2.87766 19.4998 5.82187 19.4998 9.375V10.9406C19.4998 13.0687 20.2263 15.1359 21.5529 16.8L22.2513 17.6719C22.5232 18.0094 22.5748 18.4734 22.3873 18.8625C22.1998 19.2516 21.806 19.5 21.3748 19.5H2.62476C2.19211 19.5 1.79812 19.2516 1.61081 18.8625C1.42345 18.4734 1.47614 18.0094 1.74633 17.6719L2.44523 16.8C3.77508 15.1359 4.49976 13.0687 4.49976 10.9406V9.375C4.49976 5.82187 7.10133 2.87766 10.4998 2.33812V1.5C10.4998 0.671719 11.1701 0 11.9998 0C12.8295 0 13.4998 0.671719 13.4998 1.5ZM11.6248 4.5C8.93414 4.5 6.74976 6.68437 6.74976 9.375V10.9406C6.74976 13.1859 6.09914 15.375 4.8893 17.25H19.1107C17.9013 15.375 17.2498 13.1859 17.2498 10.9406V9.375C17.2498 6.68437 15.0654 4.5 12.3748 4.5H11.6248ZM14.9998 21C14.9998 21.7547 14.6857 22.5609 14.1232 23.1234C13.5607 23.6859 12.7545 24 11.9998 24C11.2029 24 10.4388 23.6859 9.87633 23.1234C9.31383 22.5609 8.99976 21.7547 8.99976 21H14.9998Z"
      fill="#191617"
    />
    <circle
      cx="20"
      cy="4"
      r="3.5"
      fill="#EF4568"
      stroke="white"
    />
  </svg>
</template>
